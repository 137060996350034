<template>
    <span>
        <span v-if="isFree">Sign up (Free)</span>
        <span v-if="!isFree">Sign up</span>
    </span>
</template>

<script>
import { fromCurrencyAmountCDS } from '@libertyio/currency-util-js';

export default {
    props: ['price'],
    computed: {
        isFreePrice() {
            return this.price.billing_method === 'free' && this.price.tiers.length === 0;
        },
        isFreeFlatPrice() {
            return this.price.billing_method === 'flat' && this.price.tiers.length === 1 && this.price.tiers[0].flat_price_csu === 0;
        },
        isFreeUnitPrice() {
            return this.price.billing_method === 'unit' && this.price.tiers.length === 1 && fromCurrencyAmountCDS(this.price.currency, this.price.tiers[0].unit_price_cds).toCSU() === 0;
        },
        // TODO: for services, tehre's also stacked pricing and volume pricing, and it's possible that the first tier is free but other tiers are not free, so we need consider how to express that here -- maybe the button says "Sign up" as usual (and NOT "free") and the other component PriceDisplayStacked, PriceDisplayVolume, etc. will address the fact that we still need a credit card even though there's no charge right now.
        isFree() {
            return this.isFreePrice || this.isFreeFlatPrice || this.isFreeUnitPrice;
        },
    },
};
</script>
