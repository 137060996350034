<template>
    <span>
        <span v-if="isFree">Get it free</span>
        <span v-if="!isFree">Buy</span>
    </span>
</template>

<script>
import { fromCurrencyAmountCDS } from '@libertyio/currency-util-js';

export default {
    props: ['price'],
    computed: {
        isFreeFlatPrice() {
            return this.price.billing_method === 'flat' && this.price.tiers.length === 1 && this.price.tiers[0].flat_price_csu === 0;
        },
        isFreeUnitPrice() {
            return this.price.billing_method === 'unit' && this.price.tiers.length === 1 && fromCurrencyAmountCDS(this.price.currency, this.price.tiers[0].unit_price_cds).toCSU() === 0;
        },
        isFree() {
            return this.isFreeFlatPrice || this.isFreeUnitPrice;
        },
    },
};
</script>
