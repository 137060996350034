<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col style="text-align: center" cols="10" sm="8" md="6" lg="4">
            <!-- TODO: link to view account -->
            <!-- TODO: show featured products (maybe not on this page ? on catalog page maybe); allow organization to select which products are on front page, or set it up with special banners with a page builder, or something like that; and/or we can show links to view account, go to brand's homepage (link from their brandprofile), etc. we don't need to check if uesr is authenticated, because if they try to access a feature that is for authenticated users only they will get redirected to login page; -->
            <!-- TODO: the list of products needs to be styled better; if there's a product image then show the image and product name in a little card maybe -->
            <ProductDisplay :product="product" v-if="product" @buy="buy" :disabled="isProductBuyDisabled"/>
            <p class="text-caption" v-if="product && product.price_list.length === 0">
                This item is not currently available.
            </p>

            <template v-if="sameProductSameAccount">
                <!-- Continue to <router-link :to="viewcartLink">checkout</router-link> for {{ numberOfItemsDisplay }}. -->
                <p class="text-caption">You already have this product in this account.</p>
                <!-- TODO: show a 'switch accounts' button right here and show list of accounts that DON'T have this product, so user can select them (show all accounts but mark the ones that already have this product so user knows to select one that doesn't have it yet if they want to buy it for anothera ccount) so then it will switch the current account, and then the buy button will be enabled and user can buy it for the newly selected account -->
            </template>
            <template v-if="relatedProductSameAccount">
                <!-- Continue to <router-link :to="viewcartLink">checkout</router-link> for {{ numberOfItemsDisplay }}. -->
                <p class="text-caption">You already have a related product in this account.</p> <!-- TODO check if this item would be an upgrade or downgrade, and display as appropriate -->
            </template>
            <template v-if="Array.isArray(sameProductAnyAccountList) && sameProductAnyAccountList.length > 0">
                <!-- Continue to <router-link :to="viewcartLink">checkout</router-link> for {{ numberOfItemsDisplay }}. -->
                <p class="text-caption">You have this product in {{ sameProductAnyAccountList.length }} accounts.
                    <!-- TODO: icon to expand the list of accounts; or maybe underline the "X accounts" above as a toggle -->
                    <span v-for="productItem in sameProductAnyAccountList" :key="productItem.id">
                        <router-link :to="{ name: 'account-dashboard', params: { brandprofile: $route.params.brandprofile, accountId: productItem.account.id } }">
                            {{ productItem.account.name }}
                        </router-link>
                    </span>
                </p>
            </template>
            <template v-if="Array.isArray(relatedProductAnyAccountList) && relatedProductAnyAccountList.length > 0">
                <!-- Continue to <router-link :to="viewcartLink">checkout</router-link> for {{ numberOfItemsDisplay }}. -->
                <p class="text-caption">You have a related product in {{ relatedProductAnyAccountList.length }} accounts.
                    <!-- TODO: icon to expand the list of accounts; or maybe underline the "X accounts" above as a toggle -->
                    <span v-for="productItem in relatedProductAnyAccountList" :key="productItem.id">
                        <router-link :to="{ name: 'account-dashboard', params: { brandprofile: $route.params.brandprofile, accountId: productItem.account.id } }">
                            {{ productItem.account.name }}
                        </router-link>
                    </span>
                </p>
            </template>

            <!-- TODO: should we show link to search more products for this brandprofile? -->
            <!-- <template v-if="cart && Array.isArray(cart.items) && cart.items.length > 0">
                Continue to <router-link :to="viewcartLink">checkout</router-link> for {{ numberOfItemsDisplay }}.
            </template> -->
            <v-card elevation="2" v-if="isViewReady && !product">
                <v-app-bar color="red darken-2" dark flat dense>
                    <v-app-bar-title>Not found</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>Use your back button to return to the previous site, or continue to <router-link :to="searchLink">search</router-link>.</p>
                    <!-- <p>Return to the last page and contact the emergency home for details.
                    <v-btn :to="loginRoute" elevation="2" color="green darken-2 white--text" class="my-6" outlined>Sign in</v-btn>
                    <p>No account yet? <a :href="mainWebsiteURL">Sign up</a></p> -->
                    <!-- <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p> -->
                </v-card-text>
            </v-card>
            <!-- <template v-if="!isAuthenticatedReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isAuthenticatedReady">
                <template v-if="isAuthenticated">
                    <v-row style="height: 100%" align="center" justify="center">
                        <div class="app-splash-loader"></div>
                        <p class="mt-6">
                        <router-link :to="{ name: 'dashboard' }">Continue to dashboard</router-link>
                        </p>
                    </v-row>
                </template>
                <template v-if="!isAuthenticated">
                    <LoginCard/>
                </template>
            </template> -->
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { PRODUCT_CATEGORY_SERVICE } from '@/sdk/constants';
import ProductDisplay from '@/components/ProductDisplay.vue';

export default {
    components: {
        ProductDisplay,
    },
    data: () => ({
        product: null,
        isViewReady: false,
        sameProductSameAccount: null,
        relatedProductSameAccount: null,
        sameProductAnyAccountList: null,
        relatedProductAnyAccountList: null,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
            focus: (state) => state.focus,
            cart: (state) => state.cart,
            account: (state) => state.account,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        searchLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'search', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'search' };
            }
            return link;
        },
        viewcartLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-cart', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-cart' };
            }
            return link;
        },
        numberOfItemsDisplay() {
            if (typeof this.cart === 'object' && Array.isArray(this.cart.items)) {
                if (this.cart.items.length > 1) {
                    return `${this.cart.items.length} items`;
                }
                if (this.cart.items.length === 1) {
                    return '1 item';
                }
                return 'no items';
            }
            return 'no items';
        },
        isProductBuyDisabled() {
            if (this.sameProductSameAccount) {
                return true; // cannot subscribe to the same one again if the account already has it
            }
            return false; // default NOT disabled
        },
    },
    watch: {
        isAuthenticatedReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.init();
            }
        },
        // isAuthenticated(newValue, oldValue) {
        //     if (newValue && !oldValue) {
        //         this.redirectAuthenticatedUser();
        //     }
        // },
        brandprofile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.init();
            }
        },
        focus() {
            if (this.brandprofile) {
                this.init();
            }
        },
    },
    methods: {
        async loadProduct() {
            try {
                this.$store.commit('loading', { loadProduct: true });
                let request;
                // TODO: find any incoming links with just 'id' or just 'alias' in query and fix them to use 'product_id' and 'product_alias' for clarity
                if (this.$route.query.product_id || this.$route.query.id) {
                    request = { id: this.$route.query.product_id ?? this.$route.query.id };
                } else if (this.$route.query.product_ref || this.$route.query.ref) {
                    request = { ref: this.$route.query.product_ref ?? this.$route.query.ref };
                } else if (this.$route.query.product_alias || this.$route.query.alias) {
                    request = { alias: this.$route.query.product_alias ?? this.$route.query.alias };
                } else {
                    console.error('loadProduct requires product id, ref, or alias');
                    return;
                }
                const response = await this.$client.site(this.brandprofile).product.search(request);
                if (Array.isArray(response?.list) && response.list.length > 0) {
                    this.product = response.list[0];
                } else {
                    this.product = null;
                }
            } catch (err) {
                console.error('loadProduct failed', err);
            } finally {
                this.$store.commit('loading', { loadProduct: false });
                this.isViewReady = true;
            }
        },
        async checkProduct() {
            try {
                if (!this.product) {
                    console.error('checkProduct: product not loaded');
                    return;
                }
                if (PRODUCT_CATEGORY_SERVICE.includes(this.product.type) && this.isAuthenticated) {
                    // it's a service product and user is authenticated so we need to check: 1) if user has this product already, and 2) if user has another product in the same product group so we can show upgrade/downgrade buttons
                    const query = {
                        product_id: null,
                        product_group_id: null,
                        include: 'account,product',
                    };
                    if (!this.account) {
                        // TODO: should we search all user's connected accounts? that would be a separate API like site(...).user(...).accountService.search() that will search all connected accounts; because normally we only search a specific account
                        return;
                    }
                    if (this.product?.product_group_id) {
                        // search for any related service in the same product group
                        query.product_group_id = this.product.product_group_id;
                    } else {
                        query.product_id = this.product.id;
                    }
                    const accountServiceSearchResponse = await this.$client.site(this.brandprofile).account(this.account.id).accountService.search(query);
                    if (Array.isArray(accountServiceSearchResponse.list)) {
                        console.log(`checkProduct: got account service list ${JSON.stringify(accountServiceSearchResponse.list)}`);
                        if (this.account) {
                            const sameProductSameAccount = accountServiceSearchResponse.list.find((item) => item.account_id === this.account.id && item.product_id === this.product.id);
                            const relatedProductSameAccount = accountServiceSearchResponse.list.find((item) => item.account_id === this.account.id && item.product_id !== this.product.id);
                            if (sameProductSameAccount) {
                                console.log(`checkProduct: same product same account: ${JSON.stringify(sameProductSameAccount)}`);
                                this.sameProductSameAccount = sameProductSameAccount;
                            } else {
                                this.sameProductSameAccount = null;
                            }
                            if (relatedProductSameAccount) {
                                console.log(`checkProduct: related product same account: ${JSON.stringify(relatedProductSameAccount)}`);
                                this.relatedProductSameAccount = relatedProductSameAccount;
                            } else {
                                this.relatedProductSameAccount = null;
                            }
                        } else {
                            const sameProductAnyAccountList = accountServiceSearchResponse.list.filter((item) => item.product_id === this.product.id);
                            const relatedProductAnyAccountList = accountServiceSearchResponse.list.filter((item) => item.product_id !== this.product.id);
                            if (sameProductAnyAccountList.length > 0) {
                                console.log(`checkProduct: same product any account: ${JSON.stringify(sameProductAnyAccountList)}`);
                                this.sameProductAnyAccountList = sameProductAnyAccountList;
                            } else {
                                this.sameProductAnyAccountList = null;
                            }
                            if (relatedProductAnyAccountList.length > 0) {
                                console.log(`checkProduct: related product any account: ${JSON.stringify(relatedProductAnyAccountList)}`);
                                this.relatedProductAnyAccountList = relatedProductAnyAccountList;
                            } else {
                                this.relatedProductAnyAccountList = null;
                            }
                        }
                    }
                }
            } catch (err) {
                console.error('checkProduct: failed', err);
            }
        },
        async addToCart(item) {
            try {
                this.$store.commit('loading', { addToCart: true });
                const addItem = {
                    price_id: item.price.id,
                    amount: item.amount,
                    quantity: item.quantity,
                };
                const response = await this.$client.site(this.brandprofile).cart.edit({ op: 'add', item: addItem });
                if (response.isEdited) {
                    this.$store.commit('cart', response.cart);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot add to shopping cart' });
                }
            } catch (err) {
                console.error('addToCart failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot add to shopping cart', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { addToCart: false });
            }
        },
        async editInCart(item) {
            try {
                this.$store.commit('loading', { editInCart: true });
                const response = await this.$client.site(this.brandprofile).cart.edit({ op: 'edit', item }); // { priceId, quantity } or { priceId, amount } for custom amount (donation)
                if (response.isEdited) {
                    this.$store.commit('cart', response.cart);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot add to shopping cart' });
                }
            } catch (err) {
                console.error('editInCart failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot add to shopping cart', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { editInCart: false });
            }
        },
        checkout() {
            this.$nav.push(this.viewcartLink);
        },
        /**
         * Parameter `item` is { product, price, amount, quantity }
         *
         * The `price` is required; the `product` should be required but we already know `this.product` so we don't really need it;
         * `amount` and `quantity` are optonal, depends on the product type and price type.
         *
         */
        async buy(item) {
            if (this.sameProductSameAccount) {
                this.$bus.$emit('snackbar', { type: 'info', headline: 'You already have this product in this account' });
                return;
            }
            // TODO: check if product has buy mode defined (add to shopping cart and continue shopping, or add to shopping cart and go directly to pre-defined upsell product page for this product, or go directly to checkout without a shopping cart experience, etc.)
            // check if price is a custom amount; for custom amounts we only replace in cart, not add or subtract
            // const price = this.product.price_list.find((price) => price.id === item.priceId);

            // service products use the signup process which works for both authenticated and non-authenticated users
            if (PRODUCT_CATEGORY_SERVICE.includes(this.product.type)) {
                if (this.brandprofile) {
                    this.$nav.push({ name: 'brand-transaction-signup', query: { product_id: this.product.id, product_price_id: item.price.id, i: this.$route.query.i, account_id: this.$route.query.account_id } }); // NOTE: `item` has productId, priceId, but doesn't have the product type...
                } else {
                    this.$nav.push({ name: 'main-transaction-signup', query: { product_id: this.product.id, product_price_id: item.price.id, i: this.$route.query.i, account_id: this.$route.query.account_id } }); // NOTE: `item` has productId, priceId, but doesn't have the product type...
                }
            // TODO: else if (this.product.type === PRODUCT_TYPE_DONATION) { redirect to donation page , for both authenticated and non-authenticated users }
            } else {
                await this.addToCart(item);
                this.checkout();
            }
        },
        async edit(item) {
            await this.editInCart(item);
            this.checkout();
        },
        async init() {
            await this.loadProduct();
            if (this.product) {
                this.checkProduct();
            }
            this.$store.dispatch('loadCart');
            this.isViewReady = true;
        },
    },
    mounted() {
        // if (this.hostname === window.location.hostname) {
        //     // special case for front page of main site, it's not a storefront
        // }
        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
        if (this.brandprofile) {
            this.init();
        }
    },
};
</script>
