<template>
    <component :is="displayComponent" :product="product" @buy="buy" :disabled="disabled"></component>
</template>

<script>
// import { mapGetters } from 'vuex';
import { PRODUCT_CATEGORY_DONATION, PRODUCT_CATEGORY_SERVICE } from '@/sdk/constants';
import ProductDisplayDonation from '@/components/ProductDisplayDonation.vue'; // TODO: this should be clarified as a one-time donation; recurring donations should be represented as a service
import ProductDisplayStandard from '@/components/ProductDisplayStandard.vue'; // TODO: should should be clarified as a one-time purchase of merchandise; any recurring purchases (such as monthly pet food or vegetable box delivery or printer supplies) should be represented as a service
import ProductDisplayService from '@/components/ProductDisplayService.vue';

export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ProductDisplayDonation,
        ProductDisplayStandard,
        ProductDisplayService,
    },
    computed: {
    //     ...mapGetters({
    //         mainWebsiteURL: 'mainWebsiteURL',
    //     }),
    //     loginRoute() {
    //         if (this.nextRoute) {
    //             return { name: 'login', query: { next: this.nextRoute } };
    //         }
    //         return { name: 'login' };
    //     },
        displayComponent() {
            if (PRODUCT_CATEGORY_DONATION.includes(this.product.type)) {
                return 'ProductDisplayDonation';
            }
            if (PRODUCT_CATEGORY_SERVICE.includes(this.product.type)) {
                return 'ProductDisplayService';
            }
            return 'ProductDisplayStandard';
        },
    },
    methods: {
        buy(item) {
            this.$emit('buy', item);
        },
    },
};
</script>
