<template>
    <!-- TODO: this should be clarified as a one-time donation; recurring donations should be represented as a service, so the code for a recurring donation should be moved to a separate template, such as ProductDisplayRecurringDonation (not yet created) or ProductDisplayService (exists for memberships and saas and maybe could also handle recurring donations) -->
    <v-card v-if="product">
        <v-app-bar :color="primaryColor" dark flat dense>
            <v-app-bar-title>{{ product.name }}</v-app-bar-title>
        </v-app-bar>
        <v-card-text class="text-start">
            {{ product.description }}
        </v-card-text>
        <template v-if="orderedOneTimePriceList.length > 0">
            <v-card-text class="text-start">
                <p>One-time donation</p>
            </v-card-text>
            <v-list>
                <v-list-item v-for="price in orderedOneTimePriceList" :key="'onetime__'+price.id">
                    <template v-if="price.billing_method === 'flat'">
                        <v-list-item-content>
                        </v-list-item-content>
                        <v-list-item-action style="align-self: flex-start;" class="mt-4">
                            <v-btn :style="primaryButtonStyle" @click="buy(price)">
                                <PriceDisplayFlat :price="price"/>
                            </v-btn>
                        </v-list-item-action>
                    </template>
                    <template v-if="price.billing_method === 'custom'">
                        <v-list-item-content>
                            <DonateCustomAmount :priceId="price.id" @update-amount="updateCustomAmount"/>
                        </v-list-item-content>
                        <v-list-item-action style="align-self: flex-start;" class="mt-4">
                            <v-btn :style="primaryButtonStyle" @click="buy(price)">
                                <PriceButtonLabelDonate :price="price"/>
                            </v-btn>
                        </v-list-item-action>
                    </template>
                    <template v-if="price.billing_method !== 'flat' && price.billing_method !== 'custom'">
                        <v-list-item-content>
                            <PriceDisplay :price="price"/>
                        </v-list-item-content>
                        <v-list-item-action style="align-self: flex-start;" class="mt-4">
                            <v-btn :style="primaryButtonStyle" @click="buy(price)">
                                <PriceButtonLabelDonate :price="price"/>
                            </v-btn>
                        </v-list-item-action>
                    </template>
                </v-list-item>
            </v-list>
        </template>
        <template v-if="orderedRecurringPriceList.length > 0">
            <v-card-text class="text-start">
                <p>Recurring donation</p>
            </v-card-text>
            <v-list>
                <v-list-item v-for="price in orderedRecurringPriceList" :key="'recurring__'+price.id">
                    <template v-if="price.billing_method === 'flat'">
                        <v-list-item-content>
                            <PriceDisplayRecurring :price="price" class="text-end"/>
                        </v-list-item-content>
                        <v-list-item-action style="align-self: flex-start;" class="mt-4">
                            <v-btn :style="primaryButtonStyle" @click="buy(price)" :disabled="disabled">
                                <PriceDisplayFlat :price="price"/>
                            </v-btn>
                        </v-list-item-action>
                    </template>
                    <template v-if="price.billing_method === 'custom'">
                        <v-list-item-content>
                            <PriceDisplayRecurring :price="price" class="text-end"/>
                            <DonateCustomAmount :priceId="price.id" @update-amount="updateCustomAmount"/>
                        </v-list-item-content>
                        <v-list-item-action style="align-self: flex-start;" class="mt-4">
                            <v-btn :style="primaryButtonStyle" @click="buy(price)" :disabled="disabled">
                                <PriceButtonLabelDonate :price="price"/>
                            </v-btn>
                        </v-list-item-action>
                    </template>
                    <template v-if="price.billing_method !== 'flat' && price.billing_method !== 'custom'">
                        <v-list-item-content>
                            <PriceDisplayRecurring :price="price" class="text-end"/>
                            <PriceDisplay :price="price"/>
                        </v-list-item-content>
                        <v-list-item-action style="align-self: flex-start;" class="mt-4">
                            <v-btn :style="primaryButtonStyle" @click="buy(price)" :disabled="disabled">
                                <PriceButtonLabelDonate :price="price"/>
                            </v-btn>
                        </v-list-item-action>
                    </template>
                </v-list-item>
            </v-list>
        </template>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PriceDisplay from '@/components/PriceDisplay.vue';
import PriceDisplayFlat from '@/components/PriceDisplayFlat.vue';
import PriceDisplayRecurring from '@/components/PriceDisplayRecurring.vue';
import DonateCustomAmount from '@/components/DonateCustomAmount.vue';
import PriceButtonLabelDonate from '@/components/PriceButtonLabelDonate.vue';
// import { toNumber } from '@/sdk/currency';

function orderFlatCustomOther(x) {
    if (x.billing_method === 'flat') { return 1; }
    if (x.billing_method === 'custom') { return 2; }
    return 3;
}
function sortByAttr(a, b) {
    const ma = orderFlatCustomOther(a);
    const mb = orderFlatCustomOther(b);
    return ma - mb;
}

export default {
    components: {
        PriceDisplay,
        PriceDisplayFlat,
        PriceDisplayRecurring,
        DonateCustomAmount,
        PriceButtonLabelDonate,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        amountMap: {},
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        orderedOneTimePriceList() {
            // donation products typically have one or more predefined amounts and one custom amount
            // if there's a custom amount, we put it at the end
            const onetimeList = this.product.price_list.filter((price) => !price.recurring);
            onetimeList.sort(sortByAttr);
            return onetimeList;
        },
        orderedRecurringPriceList() {
            // donation products typically have one or more predefined amounts and one custom amount
            // if there's a custom amount, we put it at the end
            const recurringList = this.product.price_list.filter((price) => price.recurring);
            recurringList.sort(sortByAttr);
            return recurringList;
        },
    },
    methods: {
        updateCustomAmount({ priceId, amount }) {
            this.amountMap[priceId] = amount;
        },
        buy(price) {
            // const price = this.product.price_list.find((item) => item.id === priceId);
            // if (!price) {
            //     console.error('cannot buy item, price not found');
            //     return;
            // }
            if (price.billing_method === 'custom') {
                const amount = this.amountMap[price.id];
                if (!amount) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Please enter an amount' });
                    return;
                }
                this.$emit('buy', { product: this.product, price, amount });
            } else {
                this.$emit('buy', { product: this.product, price });
            }
        },
    },
};
</script>
