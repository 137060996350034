<template>
    <!-- TODO: this should be clarified as a one-time donation; merchandise; any recurring purchases (such as monthly pet food or vegetable box delivery or printer supplies) should be moved to a separate template, such as ProductDisplayRecurringMerchandise (not yet created) or ProductDisplayService (exists for memberships and saas and maybe could also handle recurring merchandise purchases) -->
    <v-card v-if="product">
        <v-app-bar :color="primaryColor" dark flat dense>
            <v-app-bar-title>{{ product.name }}</v-app-bar-title>
        </v-app-bar>
        <v-card-text class="text-start">
            {{ product.description }}
        </v-card-text>
        <v-list v-if="product.price_list.length > 0">
            <v-list-item v-for="price in product.price_list" :key="price.id">
                <v-list-item-content>
                    <!-- <p> BILLING METHOD: {{price.billing_method}} </p> -->
                    <PriceDisplayRecurring :price="price" class="text-end"/>
                    <PriceDisplay :price="price"/>
                    <CheckoutItemQuantity :price="price" :priceId="price.id" :quantity="1" @update-quantity="updateQuantity" v-if="price.checkout_quantity_input"/>
                </v-list-item-content>
                <v-list-item-action style="align-self: flex-start;" class="mt-4">
                    <v-btn :style="primaryButtonStyle" @click="buy(price)" :disabled="disabled">
                        <PriceButtonLabelProduct :price="price"/>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PriceDisplay from '@/components/PriceDisplay.vue';
import PriceDisplayRecurring from '@/components/PriceDisplayRecurring.vue';
import CheckoutItemQuantity from '@/components/CheckoutItemQuantity.vue';
import PriceButtonLabelProduct from '@/components/PriceButtonLabelProduct.vue';
// import { toNumber } from '@/sdk/currency';

export default {
    components: {
        PriceDisplay,
        PriceDisplayRecurring,
        CheckoutItemQuantity,
        PriceButtonLabelProduct,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        quantityMap: {},
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
    },
    methods: {
        updateQuantity({ priceId, quantity }) {
            this.quantityMap[priceId] = quantity;
        },
        buy(price) {
            // const price = this.product.price_list.find((item) => item.id === priceId);
            // if (!price) {
            //     console.error('cannot buy item, price not found');
            //     return;
            // }
            const finalizedQuantity = this.quantityMap[price.id] ?? 1;
            this.$emit('buy', { product: this.product, price, quantity: finalizedQuantity });
        },
    },
};
</script>
