<template>
    <v-text-field v-model="editableAmount" label="Donate this amount" dense :color="primaryColor" :error-messages="amountInvalidErrorMessage" @keyup.enter.prevent="updateAmount" @blur="updateAmount">
        <!-- <template #append>
            <v-btn icon :color="primaryColor" x-small @click="calculate">
                <font-awesome-icon :icon="['fas', 'calculator']" fixed-width style="font-size: 16px;"/>
            </v-btn>
        </template> -->
    </v-text-field>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { CurrencyAmount, fromCurrencyAmountCSU } from '@libertyio/currency-util-js';

export default {
    props: ['priceId', 'currency', 'amount_csu', 'min_csu', 'max_csu'],
    data: () => ({
        editableAmount: null,
        updateAmountTimestamp: null,
        amountInvalidError: false,
    }),
    computed: {
        ...mapState({
            brandprofile: (state) => state.brandprofile,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
            accentColor: 'accentColor',
        }),
        amountInvalidErrorMessage() {
            return this.amountInvalidError ? '#' : null;
        },
    },
    watch: {
        amount(newValue) {
            this.editableAmount = newValue;
        },
        editableAmount() {
            this.updateAmount();
        },
    },
    methods: {
        updateAmount() {
            if (Number.isInteger(this.updateAmountTimestamp) && this.updateAmountTimestamp + 500 > Date.now()) {
                return;
            }
            this.updateAmountTimestamp = Date.now();
            let amount = null;
            let amountCSU = null;
            if (Number.isInteger(this.editableAmount)) {
                amount = new CurrencyAmount(this.currency, this.editableAmount * 1.0);
                amountCSU = amount.toCSU(); // user entered '1', but if it's a two-digit currency such as dollars, we need it in the minimum-unit amount, e.g. 100 cents
            } else if (typeof this.editableAmount === 'string' && this.editableAmount === '') {
                this.amountInvalidError = false;
                this.$emit('update-amount', { priceId: this.priceId, currency: this.currency, amount_csu: 0 });
                return;
            } else if (typeof this.editableAmount === 'string') {
                amount = new CurrencyAmount(this.currency, Number.parseFloat(this.editableAmount));
                amountCSU = amount.toCSU();
            } else {
                this.amountInvalidError = true;
                this.$emit('update-amount', { priceId: this.priceId, currency: this.currency, amount_csu: 0 });
                return;
            }
            if (!Number.isInteger(amountCSU) || amountCSU < 0) {
                this.amountInvalidError = true;
                this.$emit('update-amount', { priceId: this.priceId, currency: this.currency, amount_csu: 0 });
                return;
            }
            if (Number.isInteger(this.min_csu) && amountCSU < this.min_csu) {
                this.amountInvalidError = true;
                this.$emit('update-amount', { priceId: this.priceId, currency: this.currency, amount_csu: 0 });
                return;
            }
            if (Number.isInteger(this.max_csu) && amountCSU > this.max_csu) {
                this.amountInvalidError = true;
                this.$emit('update-amount', { priceId: this.priceId, currency: this.currency, amount_csu: 0 });
                return;
            }
            this.editableAmount = amount.toString();
            this.amountInvalidError = false;
            this.$emit('update-amount', { priceId: this.priceId, currency: this.currency, amount_csu: amountCSU });
        },
    },
    mounted() {
        this.editableAmount = this.amount_csu ? fromCurrencyAmountCSU(this.currency, this.amount_csu).toString() : '';
    },
};
</script>
